import { Button } from "@chakra-ui/react";
import { ConnectKitButton } from "connectkit";
import "./CustomConnectButtonTheme.css";

export const CustomConnectButton = () => {
  return (
    <ConnectKitButton.Custom>
      {({ isConnected, show, truncatedAddress, ensName, chain }) => {
        return (
          <Button onClick={show} variant="primary">
            {isConnected ? ensName ?? truncatedAddress : "connect"}
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};

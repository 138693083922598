import { Box, Button, Link, Tooltip } from "@chakra-ui/react";
import "./Main.css";
import {
  type BaseError,
  useWaitForTransactionReceipt,
  useWriteContract,
  useSimulateContract,
} from "wagmi";
import { abi, address } from "./hooks/market";
import useListing from "./hooks/useListing";
import { formatEther } from "viem";
import { useEffect, useState } from "react";

interface BuyProps {
  tokenId: number;
  listingPrice: bigint;
  setUpdate: (b: boolean) => void;
  canBuy: boolean;
}

function Buy(props: BuyProps) {
  const {
    data: hash,
    isPending,
    isError,
    error,
    writeContract,
  } = useWriteContract();

  const {
    isLoading: isConfirming,
    isSuccess: isConfirmed,
    isError: failed,
  } = useWaitForTransactionReceipt({
    hash,
    confirmations: 4,
  });

  function buy() {
    writeContract({
      abi,
      address: address,
      functionName: "buy",
      args: [BigInt(props.tokenId)],
      value: props.listingPrice,
    });
  }

  useEffect(() => {
    props.setUpdate(isConfirmed);
  }, [isConfirmed]);

  function isDisabled() {
    return (
      !props.canBuy ||
      isConfirming ||
      isPending ||
      props.listingPrice === BigInt(0)
    );
  }

  return (
    <Box>
      {error && (
        <div>Error: {(error as BaseError).shortMessage || error.message}</div>
      )}

      <Tooltip
        label={
          !props.canBuy
            ? "Not tradeable. All 3 currencies need to be up 5% (monthly)."
            : ""
        }
      >
        <Button
          isLoading={isPending || isConfirming}
          isDisabled={isDisabled()}
          variant={!isDisabled() ? "primary" : "disabled"}
          onClick={buy}
        >
          {props.listingPrice > BigInt(0) &&
            `BUY ${formatEther(props.listingPrice)} ETH `}
          {props.listingPrice === BigInt(0) && "NOT LISTED"}
        </Button>
      </Tooltip>
    </Box>
  );
}

export default Buy;

import { useReadContracts } from "wagmi";
import { abi, address } from "./ascension";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

export default function useCanBuy() {
  const [canBuy, setCanBuy] = useState(false);

  const ascensionContract = {
    address: address,
    abi: abi,
    functionName: "percentageChange",
  } as const;

  const result = useReadContracts({
    contracts: [
      {
        ...ascensionContract,
        args: [1],
      },
      {
        ...ascensionContract,
        args: [2],
      },
      {
        ...ascensionContract,
        args: [3],
      },
    ],
  });

  useEffect(() => {
    if (result.data !== undefined) {
      //const changeNumber = result.data as BigNumber[];
      if (result.data !== undefined && result.data.length > 2) {
        console.log(result.data[0].result as BigNumber);
        console.log(result.data[1].result as BigNumber);
        console.log(result.data[2].result as BigNumber);
        setCanBuy(
          Number(result.data[0].result as BigNumber) > 7 &&
            Number(result.data[1].result as BigNumber) > 7 &&
            Number(result.data[2].result as BigNumber) > 7
        );
      }
    } else {
      setCanBuy(false);
    }
  }, [result.data]);

  return canBuy;
}

import {
  Box,
  Button,
  Heading,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Link as LinkRouter } from "react-router-dom";
import "./Main.css";
import useIsMobile from "./hooks/useIsMobile";
import { CustomConnectButton } from "./CustomConnectButton";

function About() {
  return (
    <VStack width="100%" position="relative">
      <Box
        width="100%"
        display="flex"
        style={{
          zIndex: 9999,
        }}
      >
        <LinkRouter to="/">
          <Button variant="primary">HOME</Button>
        </LinkRouter>
        <Spacer />
        <CustomConnectButton />
      </Box>
      <Heading as="h1" size="4xl" marginBottom="1%">
        Ascension
      </Heading>
      <Spacer />
      <Heading as="h1">About</Heading>
      <Text>
        This project was created by{" "}
        <Link textDecoration="underline" href="https://twitter.com/miragenesi">
          miragenesi
        </Link>
        , a way to recall the duality of life, for darkness cannot exist without
        light.
        <br />
        The universe will reach balance.
      </Text>
      <br />
      <Heading as="h1">Technicalities</Heading>
      <Text>
        As for{" "}
        <Link textDecor="underline" href="https://havoc.miragenesi.art">
          HAVOC
        </Link>
        , I used Chainlink price oracles to obtain the tickers for SOL/USD,
        BTC/USD, ETH/USD, hence keeping the whole project on-chain.
        <br />
        All NFTs will become trasnferrable (hence purchasable) only once all 3
        coins will be positive (more than 5% change) compared to a month ago.
        <br />
        When any of the 3 coins experiences a price decline, they will all stop
        being exchangable (hence not sellable), and the collector will have to
        wait better times to either sell them or transfer them.
        <br />
        <br />
        Only harmony and unity can bring prosperity.
      </Text>
      <br />
      <Text>
        The project uses a simple internal market to allow collectors to list
        and buy tokens.
      </Text>
      <Text>
        If you are fine with the lack of features, you can save the fees that
        you may encounter in other exchanges (5% royalties still apply).
      </Text>
      <br />
      <Text>
        The NFT recalls the logos of the 3 coins, transposed in the realm of
        divine perfection. These figures are quiet during times of decline, and
        they fly during times of growth.
      </Text>
      <br />
      <Heading as="h1">Links</Heading>
      <Text>
        Smart Contracts:{" "}
        <Link
          href="https://etherscan.io/address/0x777Bf313CFFEa35b630aFaABB080fFf81EF6b777"
          textDecor="underline"
        >
          Ascension
        </Link>
        ,{" "}
        <Link
          href="https://etherscan.io/address/0x452324E630d5d71a4aDF5f8437C8392be599a29d"
          textDecor="underline"
        >
          Market
        </Link>
      </Text>
      <Text>
        Social:{" "}
        <Link textDecor="underline" href="https://twitter.com/miragenesi">
          X
        </Link>
        ,{" "}
        <Link href="https://discord.gg/YUceSZqP" textDecor="underline">
          Discord
        </Link>
        ,{" "}
        <Link href="https://linktr.ee/miragenesi" textDecor="underline">
          Linktree
        </Link>
        ,{" "}
        <Link href={process.env.REACT_APP_OPENSEA_URL} textDecor="underline">
          Opensea
        </Link>
      </Text>
    </VStack>
  );
}

export default About;

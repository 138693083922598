import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useFetchPriceData from "./hooks/usePrice";
import { Box, Center } from "@chakra-ui/react";

Chart.register(...registerables);

function BackgroundChart({ ticker }: { ticker: string }) {
  const dataPoints = useFetchPriceData({ ticker: ticker });

  const colors = {
    ETH: "rgba(82, 213, 225, 0.446)",
    SOL: "rgba(206, 39, 248, 0.446)",
    BTC: "rgba(233, 150, 61, 0.446)",
  };

  const positiionsy = {
    ETH: "25%",
    SOL: "10%",
    BTC: "10%",
  };

  const chartData = {
    labels: dataPoints.map((_, index) => index),
    datasets: [
      {
        label: "Price",
        data: dataPoints,
        borderColor: colors[ticker as keyof typeof colors],
        backgroundColor: "rgb(0, 0, 0)",
        borderWidth: 1,
        pointBackgroundColor: "#ffffff",
        pointRadius: 1,
        fill: false,
      },
    ],
  };

  const chartOptions = {
    ainmation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box>
      {ticker === "ETH" && (
        <Center>
          <div
            style={{
              position: "absolute",
              top: positiionsy[ticker as keyof typeof positiionsy],
              margin: "0 auto",
              width: "300px",
              height: "100px",
              zIndex: -9999,
              backgroundColor: "transparent",
            }}
          >
            <Line data={chartData} options={chartOptions} />
          </div>
        </Center>
      )}
      {ticker === "BTC" && (
        <div
          style={{
            position: "absolute",
            top: positiionsy[ticker as keyof typeof positiionsy],
            right: "5%",
            width: "300px",
            height: "100px",
            zIndex: -9999,
            backgroundColor: "transparent",
          }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
      {ticker === "SOL" && (
        <div
          style={{
            position: "absolute",
            top: positiionsy[ticker as keyof typeof positiionsy],
            left: "5%",
            width: "300px",
            height: "100px",
            zIndex: -9999,
            backgroundColor: "transparent",
          }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </Box>
  );
}

export default BackgroundChart;

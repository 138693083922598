import { useReadContract } from "wagmi";
import { abi, address } from "./ascension";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

export default function usePercentageChange(tokenId: number) {
  const [change, setChange] = useState<number>();

  const result = useReadContract({
    abi,
    address: address,
    functionName: "percentageChange",
    args: [tokenId],
    query: {
      refetchInterval: 1200,
    },
  });

  useEffect(() => {
    if (result.data !== undefined) {
      const changeNumber = result.data as BigNumber;
      console.log(changeNumber);
      setChange(Number(changeNumber));
    } else {
      setChange(undefined);
    }
  }, [result.data]);

  return change;
}

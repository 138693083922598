import { useReadContract } from "wagmi";
import { abi, address } from "./ascension";

export default function useOwner(tokenId: number) {
  const result = useReadContract({
    abi,
    address: address,
    functionName: "ownerOf",
    args: [tokenId],
  });

  return result.data as string;
}

import { Text, VStack } from "@chakra-ui/react";
import "./Main.css";
import useToken from "./hooks/useToken";
import Buy from "./Buy";
import { useAccount, useEnsName } from "wagmi";
import useOwner from "./hooks/useOwner";
import List from "./List";
import useListing from "./hooks/useListing";
import { useEffect, useState } from "react";
import useIsMobile from "./hooks/useIsMobile";
import useCurrentPrice from "./hooks/useCurrentPrice";
import usePercentageChange from "./hooks/usePercentageChange";

interface CurrencyProps {
  tokenId: number;
  name: string;
  period: string;
  ticker: string;
  canBuy: boolean;
}

const FOUNDERS = {
  "0xadb51e856ecc5c3e7415af55aa50dca642187512": "vitalik.heaven.eth",
  "0x048d7b47e845af12679abe4860484c71d4182bf2": "satoshi.heaven.eth",
  "0xfad592ca29774d5cc15edd6b7d719728f96925d7": "anatoly.heaven.eth",
};

function Currency(props: CurrencyProps) {
  const isMobile = useIsMobile();
  const { address } = useAccount();
  const owner = useOwner(props.tokenId);

  const price = useCurrentPrice({ ticker: props.ticker });
  const change = usePercentageChange(props.tokenId);
  const token = useToken(props.tokenId); //useLocalSVG(`coins/${props.name}.svg`); //
  const ensName = useEnsName({
    address: owner as `0x{string}`,
  });

  const [update, setUpdate] = useState(false);
  const listingPrice = useListing(props.tokenId, update, setUpdate);
  const [displayedOwner, setDisplayedOwner] = useState<string>(
    shortenAddress(owner)
  );

  function shortenAddress(address: string) {
    if (address === undefined) {
      return "";
    }
    return `${address.substring(0, 5)}..${address.substring(39, 42)}`;
  }

  useEffect(() => {
    if (owner) {
      if (Object.keys(FOUNDERS).includes(owner.toLowerCase())) {
        setDisplayedOwner(
          FOUNDERS[owner.toLowerCase() as keyof typeof FOUNDERS]
        );
      } else if (ensName.data) {
        setDisplayedOwner(ensName.data as string);
      } else {
        setDisplayedOwner(shortenAddress(owner));
      }
    }
  }, [owner, ensName]);

  return (
    <VStack
      maxWidth={isMobile ? "100%" : "35%"}
      width={isMobile ? "100%" : "35%"}
      height="100%"
      marginBottom={isMobile ? "20px" : "0"}
    >
      <Text className="title">{props.name}</Text>
      <Text
        marginTop="-10px"
        color={
          change === undefined
            ? "white"
            : change > 0
            ? change > 7
              ? "rgb(73,241,169)"
              : "rgb(247,148,19)"
            : "rgb(255,157,147)"
        }
      >
        ${price} ({change !== undefined ? change.toString() : "..."}%)
      </Text>
      <div
        style={{
          borderColor: "rgb(199, 199, 199)",
          borderWidth: "1px",
          width: "70%",
        }}
        dangerouslySetInnerHTML={{ __html: token }}
      />
      <Text color="white" className="description">
        {displayedOwner}
      </Text>
      {owner === address && (
        <List
          tokenId={props.tokenId}
          listingPrice={listingPrice}
          setUpdate={setUpdate}
        />
      )}
      {owner !== address && (
        <Buy
          tokenId={props.tokenId}
          canBuy={props.canBuy}
          listingPrice={listingPrice}
          setUpdate={setUpdate}
        />
      )}
    </VStack>
  );
}

export default Currency;

import { useReadContract } from "wagmi";
import { abi, address } from "./market";
import { useEffect } from "react";

export default function useListing(
  tokenId: number,
  update: boolean,
  setUpdate: (update: boolean) => void
) {
  const { data, refetch } = useReadContract({
    abi,
    address: address,
    functionName: "listed",
    args: [tokenId],
  });

  useEffect(() => {
    if (update) {
      refetch();
      setUpdate(false);
    }
  }, [update]);

  return data !== undefined ? (data as bigint) : BigInt(0);
}

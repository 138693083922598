import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { defineStyleConfig } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./Main";
import BackgroundChart from "./BackgroundChart";
import About from "./About";
import { useState } from "react";
import useIsMobile from "./hooks/useIsMobile";

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "0", // <-- border radius is same for all variants and sizes
    borderColor: "#ffffff",
    borderWidth: "0.5px",
  },
  variants: {
    primary: (props) => ({
      color: "white",
      paddingTop: "4px",
      backgroundColor: "black",
    }),
    disabled: (props) => ({
      fontSize: "md",
      backgroundColor: "black",
      paddingTop: "4px",
      color: "grey",
      _hover: {
        color: "white",
        backgroundColor: "black",
      },
    }),
  },
});

const theme = extendTheme({
  fonts: {
    heading: `"CloisterBlackHeading", "Arial", "serif"`,
    body: `"MinionProRegular", "Arial", "serif"`,
  },
  styles: {
    global: {
      html: {
        background: "rgb(0, 0, 0)",
      },
      body: {
        background: "transparent",
        color: "rgb(255, 255, 255)",
        textAlign: "center",
        height: "100vh",
      },
    },
  },
  components: {
    Button,
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "about",
    element: <About />,
  },
]);

function App() {
  const isMobile = useIsMobile();
  return (
    <ChakraProvider theme={theme}>
      {!isMobile && <BackgroundChart ticker="ETH" />}
      {!isMobile && <BackgroundChart ticker="BTC" />}
      {!isMobile && <BackgroundChart ticker="SOL" />}
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
